import React from 'react'

const PauseSmall = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 10 12">
      <path
        d="M3.571 0v12H0V0h3.571zM10 0v12H6.429V0H10z"
        className="text-black fill-current"
      />
    </svg>
  )
}

export default PauseSmall
