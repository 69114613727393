import React from 'react'

const Arrow = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 18 12">
      <path
        d="M10 0L0 6l10 6V7.199L18 12V0l-8 4.799V0z"
        className="text-black fill-current"
      />
    </svg>
  )
}

export default Arrow
