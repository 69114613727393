import React from 'react'

const StoriesLinks = ({ media }) => {
  if (!media) return null
  return (
    <ul className="flex items-center -ml-6 lg:-ml-8 text-sm lg:text-lg leading-none">
      {media.map((item, index) => {
        return (
          <li key={index} className="pl-6 lg:pl-8">
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href={item.link.url}
            >
              {item.link_heading}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default StoriesLinks
