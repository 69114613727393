import React, { useState, useEffect } from 'react'

import StoriesCard from './StoriesCard'

function useWindowWidth() {
  const [width, setWidth] = useState()

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    setWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return width
}

const StoriesList = ({ data, media }) => {
  const [loadMore, setLoadMore] = useState(false)
  const width = useWindowWidth()

  // don't render component if there's no data
  if (!data.length) return null

  let storyCount

  if (width < 768) {
    storyCount = 6
  } else if (width > 768 && width < 1024) {
    storyCount = 9
  } else {
    storyCount = 8
  }

  const stories = data
  const previousStoriesFirst = stories.slice(0, storyCount)
  const previousStoriesSecond = stories.slice(storyCount)
  let previousStoriesMap = loadMore ? data : previousStoriesFirst

  return (
    <div className="bg-smoke px-ogs pt-10 pb-15 md:py-10 lg:py-15 text-sm lg:text-lg">
      <div className="md:flex md:flex-wrap md:-ml-gs -mb-10 md:-mb-5 lg:-mb-8">
        {previousStoriesMap.map((item, index) => {
          return <StoriesCard key={index} item={item} index={index} />
        })}
      </div>
      <div className="block pt-8 md:pt-10 lg:pt-15">
        <div className="flex justify-between items-end -ml-gs">
          <ul className="hidden md:flex -ml-6 lg:-ml-7 pl-gs">
            {media.media_menu.map((item, index) => {
              return (
                <li key={index} className="pl-6 lg:pl-7">
                  <a
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.link.url}
                  >
                    {item.link_heading}
                  </a>
                </li>
              )
            })}
          </ul>
          {previousStoriesSecond.length > 0 && !loadMore && (
            <div className="w-full md:w-1/4 pl-gs">
              <button
                onClick={() => {
                  setLoadMore(true)
                }}
                className="button-alt w-full"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StoriesList
