import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import StoriesMeta from './StoriesMeta'
import StoriesLinks from './StoriesLinks'
import StoriesSubheading from './StoriesSubheading'

const StoriesItem = ({ story, location, alt }) => {
  if (story) {
    return (
      <div className={`w-full ${!alt ? 'h-full' : ''}`}>
        {alt && (
          <div className="px-ogs lg:px-2x-ogs pb-2x-ogs">
            <StoriesSubheading heading={story.data.subheading}>
              <StoriesMeta story={story} location={location} alt={alt} />
            </StoriesSubheading>
            <div className="pb-6">
              <StoriesLinks media={story.data.media_links} />
            </div>
            {story.data.body.html && (
              <div
                className="rte leading-tight"
                dangerouslySetInnerHTML={{
                  __html: story.data.body.html,
                }}
              />
            )}
          </div>
        )}
        {!alt && (
          <AniLink
            fade
            to={'/podcast/' + story.uid}
            className="target-link block h-full"
          >
            <div className="h-full px-ogs lg:px-2x-ogs">
              <StoriesSubheading heading={story.data.subheading}>
                <StoriesMeta story={story} location={location} alt={alt} />
              </StoriesSubheading>
            </div>
          </AniLink>
        )}
      </div>
    )
  } else {
    return (
      <div className="p-ogs lg:p-2x-ogs">
        <p className="lg:text-lg">
          We're looking forward to sharing our first stories here towards the
          end of Q2 2020. If you'd like to know when they're live, please do
          join our mailing list.
        </p>
      </div>
    )
  }
}

export default StoriesItem
