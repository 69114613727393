import React from 'react'

const Pause = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 88 100">
      <path
        className="text-black fill-current"
        d="M31 0v100H0V0h31zm57 0v100H57V0h31z"
      />
    </svg>
  )
}

export default Pause
