import React from 'react'

const Play = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 100 100">
      <path className="text-black fill-current" d="M100 50L0 100V0z" />
    </svg>
  )
}

export default Play
