import React from 'react'
import Img from 'gatsby-image'
import ReactPlayer from 'react-player'
import { CSSTransition } from 'react-transition-group'

import Links from './StoriesLinks'
import Play from '../SVG/Play'
import Pause from '../SVG/Pause'
import PauseSmall from '../SVG/PauseSmall'
import Arrow from '../SVG/Arrow'
import Logo from '../SVG/Logo'

class StoriesPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false,
      started: false,
      seeking: false,
      played: 0,
      loaded: 0,
      duration: 0,
    }
    this.player = React.createRef()
  }

  handlePlay = () => {
    this.setState({ playing: !this.state.playing })
    this.setState({ started: true })
  }

  handleSeekMouseDown = () => {
    this.setState({ seeking: true })
  }

  handleSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.current.seekTo(parseFloat(e.target.value))
  }

  handleProgress = state => {
    if (!this.state.seeking) {
      this.setState(state)
    }
  }

  handleDuration = duration => {
    this.setState({ duration })
  }

  format = seconds => {
    if (isNaN(seconds)) {
      return `0:00`
    }

    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = this.pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${this.pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
  }

  pad = string => {
    return ('0' + string).slice(-2)
  }

  render() {
    const { story, alt } = this.props
    const { playing, started, duration, played } = this.state

    // don't render the component if there's no story, or if there's no story audio file
    if (!story || !story.data.audio_file.url) return null

    return (
      <div className="h-full bg-green">
        {!alt && (
          <div className="stories-player-index-el relative">
            <div className="absolute inset-0">
              <button
                className="h-full w-full flex justify-center items-center focus:outline-none"
                onClick={() => {
                  this.handlePlay(!playing)
                }}
              >
                <span className="w-1/6 md:w-15 lg:w-24">
                  {playing ? <Pause /> : <Play />}
                </span>
              </button>
            </div>
          </div>
        )}

        {alt && (
          <div
            className={
              `stories-player-single-el relative bg-` +
              story.data.thumbnail_secondary_colour.toLowerCase()
            }
          >
            <div className="absolute inset-0">
              <div className="h-2/3 px-ogs py-5 md:py-10">
                {story.data.thumbnail_secondary.url ? (
                  <img
                    className="h-full"
                    src={story.data.thumbnail_secondary.url}
                    alt={story.data.thumbnail_secondary.alt}
                  />
                ) : (
                  <Logo />
                )}
              </div>
            </div>
          </div>
        )}

        <div
          className={`relative bg-rose ${
            alt ? 'stories-player-single-el' : 'stories-player-index-el'
          }`}
        >
          {story.data.image.localFile && (
            <div className="absolute inset-0">
              {started && (
                <>
                  <div
                    style={{ transform: `scaleX(${played})` }}
                    className="origin-left absolute z-20 top-0 left-0 h-full bg-green blend-multiply pointer-events-none opacity-90 w-full"
                  ></div>
                  <input
                    value={played}
                    type="range"
                    min={0}
                    max={1}
                    step="any"
                    className="absolute inset-0 w-full h-full bg-green z-10"
                    onMouseDown={this.handleSeekMouseDown}
                    onChange={this.handleSeekChange}
                    onMouseUp={this.handleSeekMouseUp}
                  />
                </>
              )}
              <Img
                className="h-full w-full"
                fluid={story.data.image.localFile.childImageSharp.fluid}
                alt={story.data.image.alt}
              />
            </div>
          )}
        </div>

        <div className="h-hh flex items-center relative">
          <CSSTransition
            in={alt || started}
            timeout={200}
            unmountOnExit
            classNames={!alt ? 'fade' : ''}
          >
            <div
              className={`absolute inset-0 bg-white px-ogs h-full w-full flex items-center justify-between text-2xl lg:text-6xl ${
                alt ? 'bg-smoke' : 'bg-smoke md:bg-white'
              }`}
            >
              <div className="w-3/7">{this.format(duration * played)}</div>
              <div className="w-2/7 flex flex-no-shrink justify-between items-center">
                <button
                  onClick={() => {
                    this.player.current.seekTo(duration * played - 10)
                  }}
                  className="h-3 link-controls"
                >
                  <Arrow />
                </button>
                <button
                  onClick={() => {
                    this.handlePlay(!playing)
                  }}
                  className="h-3 link-controls"
                >
                  {playing ? <PauseSmall /> : <Play />}
                </button>
                <button
                  onClick={() => {
                    var seek =
                      duration * played + 10 > duration
                        ? Math.floor(duration)
                        : duration * played + 10
                    this.player.current.seekTo(seek)
                  }}
                  className="h-3 link-controls transform rotate-180"
                >
                  <Arrow />
                </button>
              </div>
              <div className="w-3/7 text-right">{this.format(duration)}</div>
            </div>
          </CSSTransition>
          {story.data.media_links && !alt && (
            <CSSTransition
              in={!started}
              timeout={200}
              unmountOnExit
              classNames="fade"
            >
              <div className="absolute inset-0 flex items-center pl-ogs">
                <Links media={story.data.media_links} />
              </div>
            </CSSTransition>
          )}
        </div>
        <ReactPlayer
          ref={this.player}
          url={story.data.audio_file.url}
          className="react-player"
          playing={playing}
          height="0"
          width="0"
          progressInterval={0}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
        />
      </div>
    )
  }
}

export default StoriesPlayer
