import React from 'react'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Logo from '../SVG/Logo'

const StoriesCard = ({ item, index }) => {
  return (
    <div className="md:w-1/2 lg:w-1/3 xl:w-1/4 md:pl-gs mb-10 md:mb-5 lg:mb-8">
      <AniLink fade to={'/podcast/' + item.node.uid} className="story-card">
        <div className="relative">
          <div className="story-card__image bg-grey">
            {item.node.data.thumbnail.localFile && (
              <Img
                fluid={item.node.data.thumbnail.localFile.childImageSharp.fluid}
                alt={item.node.data.thumbnail.alt}
              />
            )}
          </div>
          <div
            className={
              `story-card__overlay bg-` +
              item.node.data.thumbnail_secondary_colour.toLowerCase()
            }
          >
            <div className="h-2/5">
              {item.node.data.thumbnail_secondary.url ? (
                <img
                  className="h-full"
                  src={item.node.data.thumbnail_secondary.url}
                  alt={item.node.data.thumbnail_secondary.alt}
                />
              ) : (
                <Logo />
              )}
            </div>
            {item.node.data.excerpt && (
              <p className="story-card__excerpt">{item.node.data.excerpt}</p>
            )}
          </div>
        </div>
        <div className="flex -ml-gs">
          <div className="w-1/3 lg:w-1/2 pl-gs">
            {item.node.data.episode_number && item.node.data.episode_number}
          </div>
          <div className="w-2/3 lg:w-1/2 pl-gs">
            {item.node.data.heading && item.node.data.heading}
          </div>
        </div>
      </AniLink>
    </div>
  )
}

export default StoriesCard
