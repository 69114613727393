import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import copy from 'copy-to-clipboard'

const StoriesMeta = ({ story, location, alt }) => {
  const [copied, setCopied] = useState(false)
  function handleClick(target) {
    setCopied(!copied)
    copy(target)
  }

  return (
    <div className="w-full flex justify-between">
      <div className="w-1/3">{story.data.date && story.data.date}</div>
      <div className="w-1/3 text-center">
        {story.data.episode_number && story.data.episode_number}
      </div>
      <div className="w-1/3 text-right">
        {!copied && (
          <button
            onClick={() => {
              handleClick(!alt ? location.href + story.uid : location.href)
            }}
            className="link -m-3 p-3"
          >
            Share
          </button>
        )}
        <CSSTransition
          in={copied}
          timeout={200}
          unmountOnExit
          classNames="fade"
        >
          <div>Copied!</div>
        </CSSTransition>
      </div>
    </div>
  )
}

export default StoriesMeta
