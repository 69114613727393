import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageLead from '../components/Page/PageLead'
import PageHero from '../components/Page/PageHero'
import PageStickyBar from '../components/Page/PageStickyBar'
import PageHeading from '../components/Page/PageHeading'
import StoriesItem from '../components/Stories/StoriesItem'
import StoriesList from '../components/Stories/StoriesList'
import StoriesPlayer from '../components/Stories/StoriesPlayer'

const StoriesPage = ({ location, data }) => {
  const page = data.prismicStoriesIndex.data
  const media = data.prismicGlobal.data
  const latestStory = data.latestStory.edges.length
    ? data.latestStory.edges[0].node
    : null
  const previousStories = data.previousStories.edges
  return (
    <Layout title={page.title} headerColor="green">
      <SEO
        title={page.meta_title || page.title}
        description={page.meta_description || ''}
        location={location}
      />
      <PageLead lead={page.lead.html} color="grey" />
      <PageHero image={page.hero} color="peach" alt />
      <div className="relative pt-hh">
        <PageStickyBar headings={['Latest Episode']} />
        <div className="relative z-10 md:flex">
          <PageHeading heading="Latest Episode" bg="white" />
          <div className="relative md:w-1/2 bg-green z-10">
            <div className="md:sticky md:top-hh md:left-0 md:h-stories-player-index bg-green">
              <StoriesPlayer story={latestStory} />
            </div>
          </div>
          <div className="md:w-1/2 md:bg-smoke">
            <StoriesItem story={latestStory} location={location} />
            {previousStories.length > 0 && <div className="h-hh bg-grey"></div>}
          </div>
        </div>
      </div>
      {previousStories.length > 0 && (
        <div className="relative bg-grey">
          <PageStickyBar headings={['Other Episodes']} alt />
          <div className="relative bg-white z-10">
            <PageHeading heading="Other Episodes" bg="grey" />
            <div className="relative z-10">
              <StoriesList data={previousStories} media={media} />
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

StoriesPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default StoriesPage

export const pageQuery = graphql`
  query {
    prismicStoriesIndex {
      data {
        title
        hero {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        lead {
          html
        }
        meta_description
        meta_title
      }
    }
    latestStory: allPrismicStoriesSingle(
      limit: 1
      sort: { order: DESC, fields: data___date }
    ) {
      edges {
        node {
          uid
          data {
            audio_file {
              url
            }
            date(formatString: "DD/MM/YY")
            subheading
            media_links {
              link {
                url
              }
              link_heading
            }
            image {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2400, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    previousStories: allPrismicStoriesSingle(
      skip: 1
      sort: { order: DESC, fields: data___date }
    ) {
      edges {
        node {
          uid
          data {
            heading
            episode_number
            excerpt
            thumbnail {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2400, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            thumbnail_secondary_colour
            thumbnail_secondary {
              alt
              url
            }
          }
        }
      }
    }
    allStories: allPrismicStoriesSingle {
      edges {
        node {
          uid
        }
      }
    }
    prismicGlobal {
      data {
        media_menu {
          link {
            url
          }
          link_heading
        }
      }
    }
  }
`
