import React from 'react'

// NOTE: this component should be moved out of the stories folder and renamed as something more generic as it is also used on the events tempalte

const StoriesSubheading = ({ children, heading, ratio }) => {
  return (
    <div className="py-8 h-full flex flex-col">
      <div className="text-sm lg:text-lg">{children}</div>
      {heading && (
        <div
          className={`flex-grow flex items-center max-w-md mx-auto ${
            ratio ? '' : 'py-32'
          }`}
        >
          <h2 className="target-link__target text-2xl lg:text-4xl xl:text-6xl leading-tight text-center uppercase">
            {heading}
          </h2>
        </div>
      )}
    </div>
  )
}

export default StoriesSubheading
